<template>
    <v-card color="transparent" elevation="0" flat>
        <v-col cols="12">
            <v-expansion-panels dark>
                <v-expansion-panel>
                    <v-expansion-panel-header>Anleitung für Trainer</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-row>
                            <v-col cols="12" v-if="steps.length === 0">
                                <v-alert type="info"> Füge einen ersten Schritt hinzu </v-alert>
                            </v-col>
                            <v-col cols="12">
                                <v-expansion-panels>
                                    <v-expansion-panel v-for="(step, index) in steps" :key="index">
                                        <v-expansion-panel-header>{{ index + 1 }}. Schritt</v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <template v-slot:header>
                                                {{ step.title }}
                                            </template>
                                            <v-card>
                                                <v-card-text>
                                                    <v-text-field label="Titel" v-model="step.title"></v-text-field>
                                                    <v-textarea label="Text" v-model="step.text"></v-textarea>
                                                </v-card-text>
                                                <v-card-actions class="d-flex justify-center">
                                                    <v-btn color="red" @click="deleteStep(index)">Löschen</v-btn>
                                                </v-card-actions>
                                            </v-card>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-col>

                            <v-col class="d-flex justify-start" cols="6">
                                <v-btn color="primary" @click="addStep"><v-icon>mdi-plus</v-icon></v-btn></v-col
                            >
                            <v-col class="d-flex justify-end" cols="6">
                                <v-btn color="primary" @click="saveManual">Speichern</v-btn></v-col
                            >
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                    <v-expansion-panel-header>Timestop Formular Text</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-expansion-panels>
                            <v-expansion-panel
                                v-for="(studio, studioId) in timestopTextStudios"
                                :key="studioId + '-studioSettings'">
                                <v-expansion-panel-header :color="studio.text && studio.text.length > 0 ? '' : 'red'">
                                    {{ studio.name }}</v-expansion-panel-header
                                >
                                <v-expansion-panel-content>
                                    <v-row>
                                        <v-col class="my-0 py-0" cols="12">
                                            <v-card-title class="white--text px-0 mx-0 pb-0">Formular Text</v-card-title>
                                        </v-col>
                                        <v-col class="pt-0 mt-0" cols="12">
                                            <v-textarea class="pt-0 mt-0" dark v-model="studio.text"></v-textarea>
                                        </v-col>

                                        <v-col cols="12">
                                            <v-btn @click="updateFormText(studioId, studio.text)" color="primary"
                                                >Speichern</v-btn
                                            >
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-col>
    </v-card>
</template>

<script>
import api from '@/api'
import signaturePad from '@/components/signaturePad/signaturePad.vue'
export default {
    components: { signaturePad },
    props: {},
    data() {
        return {
            steps: [],
            timestopTextStudios: {},
        }
    },
    created() {
        this.fetchTimestopText()
        this.fetchTimestopManual()
    },
    methods: {
        saveSignatureStudio(studioId, signature) {
            this.timestopTextStudios[studioId].signature = signature
        },
        async fetchTimestopManual() {
            try {
                let steps = await api.fetchTimestopManual()

                this.steps = steps || []
            } catch (e) {
                console.log(e)
                this.$toast.error('Fehler beim laden der Anleitung')
            }
        },
        async saveManual() {
            try {
                let status = await api.saveTimestopManual(this.steps)
                this.$toast.success('Anleitung wurde gespeichert')
            } catch (e) {
                this.$toast.error('Fehler beim speichern')
            }
            console.log(this.steps)
        },
        addStep() {
            this.steps.push({ title: '', text: '' })
            console.log(this.steps)
            this.$forceUpdate()
        },
        deleteStep(index) {
            this.steps.splice(index, 1)
            this.$forceUpdate()
        },
        async updateFormText(studio, text) {
            if (text.length < 1) {
                this.$toast.error('Bitte fülle das Textfeld aus.')
                return
            }
            try {
                let status = await api.updateTimestopFormText({ studio, text })
                this.$toast.success('Timestop Text wurde gespeichert.')
            } catch (e) {
                this.$toast.error('Fehler beim speichern, versuche es erneut.')
            }
            console.log(studio, text)
        },
        async fetchTimestopText() {
            try {
                let text = await api.fetchTimestopFormText()
                this.timestopTextStudios = text
                console.log(text)
            } catch (e) {
                this.$toast.error('Fehler beim laden der Daten, versuche es erneut.')
            }
        },
    },
    watch: {},
}
</script>